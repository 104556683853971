import React from "react"
import { Link } from "gatsby"

const HeaderNew = () => {
  return (
    <div className="fixed bg-black w-full p-4 z-50">

      <header className="w-full max-w-screen-xl mx-auto">
        <nav className="flex items-center justify-between mx-auto">
          <div className="flex items-center">
            <Link to="/">
              <h2 className="text-white text-xl">Gatsby Demo Site</h2>
            </Link>
          </div>

          <div className="flex-shrink-0 md:w-48">
            <Link to="/">
              <button className="w-full bg-site-green hover:bg-site-green-100 text-white px-6 py-2 font-semibold uppercase tracking-wider">
                Contact Us
              </button>
            </Link>
          </div>
        </nav>
      </header>
    </div>
  )
}

export default HeaderNew