import React from "react"

import Layout from "../components/layout/layout"
import Seonew from "../components/seo-new"
import HomeHeroSectionNew from "../components/sections/home-hero-section-new"

const IndexPage = () => (
  <Layout>
    <Seonew
      title="Gatsby with Prismic Demo Site"
      description="Gatsby JS website development is our forte. We build blazing fast and secure website for serious business."
    />
    <HomeHeroSectionNew />
  </Layout>
)

export default IndexPage
