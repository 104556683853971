import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const HomeHeroSectionNew = () => {
  const data = useStaticQuery(graphql`
    query HomeHeroSectionNewQuery {
      prismicHomePage {
        data {
          hero_title
          hero_subtitle
        }
      }
    }
  `)

  const doc = data.prismicHomePage

  return (
    <div className='bg-gray-900 h-screen flex justify-center items-center px-4 pt-36 xl:pt-52 pb-24 xl:pb-48'>
      <div className='w-full max-w-screen-xl flex flex-col xl:flex-row items-center mx-auto'>
        <div className='w-full md:w-10/12 xl:w-2/3 flex flex-col items-center mx-auto'>

          <h1 className='text-4xl md:text-5xl font-medium text-white text-center uppercase mt-6'>
            <span className='leading-tight'>{doc.data.hero_title}</span>
          </h1>

          <h2 className='w-full lg:w-9/12 lg:text-lg text-white text-base text-center mt-6'>{doc.data.hero_subtitle}</h2>
        </div>
      </div>
    </div>
  )
}

export default HomeHeroSectionNew